import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ResursePage = (props) => (
  <Layout  location={props.location}>
        <SEO title="Resurse" />

      <h1>Resurse</h1>

      <h2>CONJUGATOR</h2>
          <p>
              <a target={'conjugare'} href="https://www.conjugare.ro/">conjugare.ro</a>
          </p>

        <br/>
        <br/>
        <br/>

      <h2>DEXONLINE</h2>
        <p>
            <a target={'dexonline'} href="https://dexonline.ro/">dexonline.ro</a>
        </p>

        <p>
            Din acest site care cuprinde numeroase dicționare ale limbii române vă recomandăm, atunci când doriți să vă informați
            despre categoria sau sensul unui cuvânt: DEX '09 (2009). Cele mai noi recomandări oficiale privind categoria sau flexiunea
            le puteți găsi pe pagină mai jos: DOOM 2 (2005) sau DOR (2008).
        </p>



  </Layout>
)

export default ResursePage
